@import '../../sass/variables';

.wrapper {
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;

    &-track {
      border-radius: 2px;
      box-shadow: inset 0 0 6px $colorGray500;
    }

    &-thumb {
      background-color: $colorGray400;
      border-radius: 2px;
    }
  }

  &.invisibleScrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
